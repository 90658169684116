.booking-banner {
  position: relative;
  padding: 20px;
  text-align: center;
  color: white;
  background: #FFFFFF4D;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  max-width: 300px;
  margin: 20px auto;
}

.booking-banner h2 {
  font-size: 1.5rem;
  color: #b00000; /* Red color matching the heading */
  margin-bottom: 10px;
}

.phone-number {
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone-link {
  color: #FFFFFF;
}
.phone-number span {
  font-size: 1.5rem;
  margin-right: 10px;
  color: #FFFFFF !important;
}

.not-licensed {
  color: #b00000;
  font-size: 0.9rem;
  font-weight: bold;
  margin-top: 15px;
  text-transform: uppercase;
  text-decoration: underline;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .booking-banner {
    padding: 15px;
    max-width: 250px;
  }

  .booking-banner h2 {
    font-size: 1.3rem;
    margin-bottom: 8px;
  }

  .phone-number {
    font-size: 1.5rem;
  }

  .phone-number span {
    font-size: 1.2rem;
  }

  .not-licensed {
    font-size: 0.8rem;
    margin-top: 10px;
  }
}

@media (max-width: 576px) {
  .booking-banner {
    padding: 10px;
    max-width: 220px;
  }

  .booking-banner h2 {
    font-size: 1.2rem;
    margin-bottom: 6px;
  }

  .phone-number {
    font-size: 1.3rem;
  }

  .phone-number span {
    font-size: 1rem;
  }

  .not-licensed {
    font-size: 0.75rem;
    margin-top: 8px;
  }
}
