/* Form and Image Container */
.callback-form {
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffecec;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

/* Image Container */
.callback-image-container {
  max-width: 600px;
  margin: 0 auto;
}

/* Image Styling */
.callback-image {
  border-radius: 8px;
}

/* Contact Info Box Positioning */
.contact-info-box {
  top: -50px;
  left: -40px;
  width: auto;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .contact-info-box {
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
  }

  .callback-form,
  .callback-image-container {
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact-info-box {
    top: -30px;
    left: 0;
    right: 0;
    width: auto;
    text-align: center;
    transform: none;
  }

  .callback-form,
  .callback-image-container {
    max-width: 80%;
    margin: 0 auto;
  }
}

/* Input Field Styling */
.styled-input {
  background: #fff !important;
  border: none !important;
  border-radius: 0.5rem !important;
  padding: 1rem !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
  font-size: 1rem !important;
  color: #888 !important;
}

.styled-input:focus {
  outline: none !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
}

/* Select Dropdown Styling */
.styled-input.form-select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-position: right 1rem center !important;
  background-repeat: no-repeat !important;
}

/* Calendar Icon Styling */
.styled-input-icon {
  background: #fff !important;
  border: none !important;
  border-radius: 0.5rem 0 0 0.5rem !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
  padding: 1rem !important;
  display: flex !important;
  align-items: center !important;
  color: #888 !important;
}

/* Button Styling */
.btn-danger {
  background-color: #b22222;
  border: none;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 1.25rem;
  transition: background-color 0.3s ease;
}

.btn-danger:hover {
  background-color: #8b0000;
}

.input-group {
  display: flex !important;
  flex-wrap: nowrap !important;
}

/* Adjustments for form elements */
.styled-input {
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  color: #333;
}

.styled-input:focus {
  border-color: #b22222  !important;
  box-shadow: 0px 4px 10px rgba(178, 34, 34, 0.2) !important;
  outline: none !important;
}

/* Adjust Button */
.btn-danger {
  background-color: #b22222;
  color: #fff;
  font-size: 1.25rem;
  padding: 1rem;
  width: 100%;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.btn-danger:hover {
  background-color: #8b0000;
}

/* Add padding and spacing to the form container */
.callback-form {
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffecec;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

/* Textarea styling */
.styled-input.textarea {
  resize: none !important;
}
