/* Hero.css */

/* Base Styles */
.hero-section {
  min-height: 80vh;
  position: relative;
}

.hero-slider {
  height: 80vh;
  position: relative;
}

.booking-banner-container {
  top: 20%;
  left: 20%;
  transform: translate(-50%, -50%);
  max-width: 90%;
}

/* Overlay Text Styling */
.overlay-text {
  backdrop-filter: blur(5px);
  text-align: center;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Title styling */
.hero-title {
  font-size: 3rem;
  line-height: 1.2;
}

/* Arrow Button */
.arrow-btn {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.arrow-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Medium Screen Adjustments */
@media (max-width: 768px) {
  .hero-section,
  .hero-slider {
    min-height: 60vh;
    height: 60vh;
  }

  .booking-banner-container {
    top: 18%;
    left: 20%;
    transform: translate(-50%, -50%);
  }

  .overlay-text {
    padding: 1rem;
    max-width: 95%;
  }

  .hero-title {
    font-size: 2rem;
  }

  .arrow-btn {
    padding: 0.5rem;
  }
}

/* Small Screen Adjustments */
@media (max-width: 576px) {
  .hero-section,
  .hero-slider {
    min-height: 50vh;
    height: 50vh;
  }

  .booking-banner-container {
    top: 17%;
    left: 30%;
    transform: translate(-50%, -50%);
  }

  .overlay-text {
    padding: 0.75rem;
    font-size: 0.85rem;
    max-width: 90%;
  }

  .hero-title {
    font-size: 1.5rem;
  }

  .arrow-btn {
    padding: 0.4rem;
  }
}
