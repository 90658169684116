/* PromoBanner.css */

.promo-banner {
  background-color: #9B0000; /* Bootstrap "bg-danger" color */
  padding: 5rem 1rem;
  height: 300px;
}

.promo-banner-background {
  background-image: url("../../../assets/images/christmas/promo.png"); /* Adjust path as needed */
  background-size: contain;
  z-index: 0;
}

.promo-banner-content {
  position: relative;
  z-index: 1;
}

.promo-subtext {
  font-family: "Lobster", cursive;
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
}

.promo-main-text {
  font-family: "Lobster";
  font-size: 52px;
  font-weight: 400;
  line-height: 65px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .promo-banner {
    padding: 3rem 1rem;
    height: 350px;
  }

  .promo-subtext {
    font-size: 1.5rem;
  }

  .promo-main-text {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .promo-banner {
    padding: 2rem 1rem;
    height: 300px;
  }

  .promo-subtext {
    font-size: 1.2rem;
  }

  .promo-main-text {
    font-size: 1.6rem;
    line-height: 1.1;
  }
}
