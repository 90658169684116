.custom-button {
  background-color: #9b0000 !important; /* Dark red color */
  color: white !important;
  font-size: 1.5rem !important; /* Adjust font size for responsiveness */
  font-weight: bold;
  border: none !important;
  border-radius: 10px !important;
  padding: 1rem 1.5rem !important; /* Adjust padding for responsiveness */
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.custom-button:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.icon {
  margin-left: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-button {
    font-size: 1.2rem !important; /* Slightly smaller font size for tablets */
    padding: 0.75rem 1.25rem !important; /* Adjust padding */
  }
}

@media (max-width: 576px) {
  .custom-button {
    font-size: 1rem !important; /* Smaller font size for mobile */
    padding: 0.5rem 1rem !important; /* Compact padding */
  }

  .icon {
    font-size: 1rem; /* Adjust icon size for mobile */
    margin-left: 6px;
  }
}
