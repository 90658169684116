/* Previous styles remain the same */

/* Fix for multi-line headings with border bottom */
.border-yellow-bottom {
  position: relative;
  border-bottom: none;
  padding-bottom: 15px;
}

.border-yellow-bottom::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 3px;
  background-color: #ffd700;
}

/* Make heading container full width to contain the border */
.text-center h2 {
  display: inline-block;
  max-width: 100%;
}

.text-center h2 span {
  display: inline-block;
  max-width: 100%;
}
