/* GetQuote.css */

.get-quote-section {
    background-color: #ffeeee;
    padding: 2rem 1rem;
    position: relative;
    overflow: hidden;
  }
  
  .get-quote-fruits {
    width: 10rem;
    height: 20rem;
    background-size: cover;
    background-position: center;
  }
  
  .get-quote-tree {
    width: 20rem;
    height: 27rem;
    background-size: cover;
    background-position: center;
  }
  
  .get-quote-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
  }
  
  .get-quote-underline {
    width: 150px;
    height: 4px;
    background-color: #b30000;
    margin: 1rem 0; /* Center the underline */
  }
  
  .feature-list {
    margin-top: 2rem;
  }
  
  .feature-item {
    font-size: 1.2rem;
    color: #333;
    display: flex;
    align-items: center;
    text-align: left;
  }
  
  .feature-icon {
    font-size: 1.5rem;
    color: #b30000;
    flex-shrink: 0;
  }
  
  .get-quote-button {
    background-color: #b30000;
    color: #fff;
    padding: 0.75rem 2rem;
    font-size: 1.1rem;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin: 0 auto;
    display: block; /* Center the button */
    text-align: center;
  }
  
  .get-quote-button:hover {
    background-color: #930000;
  }
  
  /* Responsive Adjustments */
  
  /* For tablets */
  @media (max-width: 768px) {
    .get-quote-section {
      padding: 1.5rem 0.5rem;
    }
  
    .get-quote-title {
      font-size: 2.4rem;
    }
  
    .get-quote-underline {
      width: 120px;
      height: 3px;
    }
  
    .feature-item {
      font-size: 1.5rem;
    }
  
    .feature-icon {
      font-size: 1.5rem;
    }
  
    .get-quote-fruits,
    .get-quote-tree {
      display: none; /* Hide decorative images on smaller screens */
    }
  
    .get-quote-button {
      padding: 0.6rem 1.5rem;
      font-size: 1.5rem;
    }
  }
  
  /* For mobile devices */
  @media (max-width: 576px) {
    .get-quote-section {
      padding: 1rem 0.5rem;
    }
  
    .get-quote-title {
      font-size: 1.7rem;
    }
  
    .get-quote-underline {
      width: 100px;
      height: 3px;
    }
  
    .feature-item {
      font-size: 1.2rem;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .feature-icon {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }
  
    .get-quote-button {
      padding: 0.5rem 1.2rem;
      font-size: 1.1rem;
    }
  }
  