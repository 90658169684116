body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none !important;
}
.navmen {
  justify-content: flex-end;
}
.navbar-brand {
  font-size: 3vw !important;
}
.nav-logo {
  width: 7.2vw;
}
.nav-link {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin-right: 35px;
  text-align: center;
  color: #ffffff !important;
}
.nav-link:hover {
  color: #ffcf14 !important;
}
.navbar-bg-color {
  background-color: #323232;
}
.main-nav {
  background-color: #323232;

  position: fixed;
  width: 100%;
  z-index: 200;
  top: 0%;
}
.cstm-nav-setting {
  padding-right: 80px !important;
  padding-left: 80px !important;
}
.top-bar-wrapper {
  background-color: #ffcf14;
  margin-top: 9%;
}
.top-bar-info-main {
  flex-direction: column;
  display: flex;
  flex-basis: fit-content;
  margin: 0 auto;
  padding: 15px 0;
  padding-bottom: 0;
}
.top-bar-text-link {
  font-family: var(--robboto-font);
  font-style: normal;
  font-weight: 500;
  font-size: 23.2612px;
  line-height: 50px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0;
  margin: 0 15px;
  color: #fafafa;
}
.lincensed-para {
  display: block;
  width: 100%;
  font-family: var(--robboto-font);
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  color: #323232;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
  text-transform: uppercase;
}
.top-bar-phone {
  font-size: 30px;
  color: black;
  font-family: var(--robboto-font);
  text-decoration: none;
}

/* ==== Hero Banner Section ==== */

.hero-banner-sect {
  min-height: 800px;
  position: relative;
  display: inherit;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 140px;
}

.hero-banner-sect .video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
}

.hero-banner-sect .video-container video {
  max-width: max-content;
  height: 100%;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}
.hero-banner-sect .video-color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: lightblue;
  width: 100%;
  height: 100%;
}

.hero-banner-sect .banner-head-text {
  font-style: normal;
  font-weight: 900;
  font-size: 5vw;
  line-height: 88px;
  font-family: "Charis SIL", serif;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 30px;
}
.hero-banner-sect .banner-desc-text {
  font-style: italic;
  font-weight: 400;
  font-size: 32px;
  line-height: 50px;
  margin-bottom: 60px;
  text-align: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: #ffffff;
}
.hero-banner-sect .banner-anch-text {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  text-transform: uppercase;
  font-family: "Charis SIL", serif;
  text-align: center;
  color: #ffffff;
}
.hero-banner-sect .banner-anch-text {
  display: block;
  margin: 0 auto;
  background: #ffcf14;
  border-radius: 4px;
  padding: 15px 30px;
  max-width: fit-content;
  text-decoration: none;
}
.hero-banner-sect .banner-anch-text:hover {
  background: #323232;
  color: #fff;
}
.hero-banner-sect .banner-scroll-btn-row {
  text-align: center;
  position: absolute;
  bottom: 40px;
  right: 0;
  left: 0;
}

.hero-banner-sect .banner-scroll-btn-row .banner-scroll-txt {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  text-align: center;
  text-transform: uppercase;
  font-family: var(--robboto-font);
  color: #ffffff;
  margin-top: 10px;
  display: block;
}

.seperator-sect {
  height: 50px;
  background: #323232;
}

/* === Great Value Sect === */

.great-value-sect {
  padding-top: 50px;
}

.great-value-sect .great-value-sect-col-wrapper {
  max-width: 90%;
  margin: 0 auto;

  padding: 60px 0;
  background: #000102;
  opacity: 0.7;
  border-radius: 6px;
}

.great-value-sect .great-value-first-head-text {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  font-family: "Charis SIL", serif;

  text-align: center;

  color: #ffffff;
}
.great-value-sect .great-value-first-head-text .great-value-plus-symbol {
  color: #ffcf14;
}
.great-value-sect .great-value-equal-to-sign {
  color: #ffcf14;
  font-style: normal;
  font-weight: 900;
  font-size: 72px;
  line-height: 46px;

  margin-top: 30px;
  text-align: center;
}

.great-value-sect .great-value-bordered-head {
  font-style: normal;
  font-weight: 900;
  font-size: 56px;
  line-height: 46px;
  font-family: "Charis SIL", serif;

  text-align: center;
  color: #ffcf14;
  display: block;
  padding: 20px 40px;
  border: 2px solid;
  margin: 0 auto;
  max-width: fit-content;
  margin-top: 50px;
}

/* Commitment section  */
.our-commitment-sect {
  padding-top: 50px;
}

.our-commitment-sect .our-commitment-head-text {
  text-align: center;
  text-decoration: underline;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 24px;

  font-family: "Charis SIL", serif;
  text-align: center;
  text-decoration-line: underline;

  color: #3b3b3b;
}
.our-commitment-sect .our-commitment-head-row {
  margin: 50px 0;
}

.our-commitment-sect .our-commitment-single-listing-wrapper {
  text-align: center;
  max-width: 100%;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 370px;
}

.our-commitment-sect .single-commitment-head-text {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  /* identical to box height, or 100% */
  color: #323232;
  text-align: center;
  margin: 35px 0 20px 0;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.our-commitment-sect .single-commitment-desc-para {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 34px;
  /* or 170% */
  font-family: var(--robboto-font);
  text-align: center;

  color: #323232;
}
.our-commitment-sect .our-commitmen-single-listing {
  margin-bottom: 40px;
}

/* === Contact Form Sect === */
.contact-form-sect {
  /* background: url('../images/contact-is-bg.png');
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: center center; */
  padding-bottom: 60px;
  padding-top: 60px;
}
.contact-form-sect .sch-appointment-anch {
  display: block;
  background: #ffcf14;
  color: #fff;
  font-size: 25px;
  padding: 20px 10px;
  border-radius: 15px;
  text-align: center;
  border: solid 1px #ffcf14;
  font-family: "Roboto";
  width: 600px;
  margin: 0 auto;
}
.contact-form-sect .sch-appointment-anch:hover {
  background: #000;
}
.contact-form-sect .contact-head-text {
  font-style: normal;
  font-weight: 900;
  font-size: 46px;
  line-height: 42px;
  /* identical to box height, or 91% */
  margin: 70px 0;
  text-align: center;
  color: #323232;
  position: relative;
  font-family: "Charis SIL", serif;
}

.contact-form-sect .contact-head-text:after {
  content: "";
  position: absolute;
  width: 120px;
  height: 1px;
  top: 52px;
  left: 46%;
  border-bottom: 3px solid #323232;
}

.contact-form-sect .contact-desc-para {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 42px;
  /* identical to box height, or 175% */

  font-family: "Charis SIL", serif;
  text-align: center;
  text-transform: uppercase;
}
.contact-form-sect .contact-form-black-div-wrapper {
  max-width: 95%;
  margin: 0 auto;
  padding: 0 45px;
}
.contact-form-sect .contact-form-black-div {
  background: #323232;
  padding: 50px;
  background: #323232;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  border-radius: 26px;
  min-height: 700px;
}

.contact-form-sect .contact-form-main-div label,
.form-bottom-para {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  font-family: "Charis SIL", serif;
}
.contact-form-sect .contact-form-main-div .form-bottom-para {
  margin: 0 auto;
}
.contact-form-sect .contact-form-main-div .form-group {
  padding: 0;
  padding-right: 10px;
  width: 100%;
}

.contact-form-sect .contact-form-main-div textarea {
  width: 100%;
  border-radius: 5px;
  resize: none;
  outline: none;
  padding: 15px;
}

.contact-form-sect .contact-form-main-div .contact-form-submit {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  /* identical to box height, or 108% */

  text-align: center;
  text-transform: uppercase;
  background: #ffcf14;
  border: 1px solid #ffcf14;
  border-radius: 4px;
  display: block;
  margin: 0 auto;
  margin-top: 100px;
  color: #fff;

  padding: 15px 40px;
  cursor: pointer;
  font-family: var(--robboto-font);
}
.contact-form-sect .contact-form-main-div .contact-form-submit:hover {
  background: #323232;
  border: 1px solid #fff;
}

.contact-form-main-div .screen-reader-response {
  color: red;
  font-size: 20px;
  font-weight: 500;
  font-family: var(--robboto-font);
}
.contact-form-main-div .screen-reader-response ul {
  display: none;
}

.contact-form-main-div .wpcf7-not-valid-tip {
  color: red;
  font-size: 16px;
  margin-top: 10px;
  display: block;
  font-family: var(--robboto-font);
}

.contact-form-main-div .wpcf7-response-output {
  color: #fff;
  text-align: center;
  margin-top: 22px;
  font-size: 20px;
  font-family: var(--robboto-font);
}

.who-we-are-sect {
  padding: 50px 0 100px 0;
}
.who-we-are-sect .who-we-are-head-div {
  margin-bottom: 60px;
}
.who-we-are-sect .who-we-are-head-text {
  font-style: normal;
  font-weight: 900;
  font-size: 46px;
  line-height: 42px;
  color: #323232;
  font-family: "Charis SIL", serif;
}
/* .who-we-are-sect .who-we-are-head-text:after {
  content: "";
  position: absolute;
  width: 10%;
  height: 1px;
  top: 70px;
  left: 2%;
  border-bottom: 3px solid #ffcf14;
} */
.who-we-are-sect .who-we-are-para {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 42px;
  color: #323232;
  font-family: var(--robboto-font);
}

footer {
  background: #323232;
}
footer .footer-trusted-row {
  padding: 70px 0;
}

footer .trusted-img-wrapper {
  text-align: center;
}
.footer-second-row {
  margin: 30px 0;
}

.footer-second-row .footer-small-head {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 22px;
  /* identical to box height, or 110% */
  position: relative;
  margin-bottom: 50px;
  color: #ffffff;

  font-family: "Charis SIL", serif;
}

.footer-second-row .footer-small-head:after {
  content: "";
  position: absolute;
  width: 80px;
  height: 1px;
  top: 32px;
  left: 1%;
  border-bottom: 3px solid #fff;
}
.footer-second-row .footer-anch {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  color: #ffffff;
  font-family: "Charis SIL", serif;
}
.footer-second-row .footer-anch:hover {
  color: #ffcf14;
}
.footer-timing-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-decoration: none;
  color: #ffcf14;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.footer-second-row .footer-social-links-div .footer-social-links-wrapper {
  width: fit-content;
  margin: 0 auto;
}

.footer-copy-right-wrapper {
  background: #000;
}
.footer-copy-right-wrapper {
  text-align: center;
  padding: 25px 0;
}
.footer-copy-right-wrapper p {
  margin-bottom: 0;
}
.footer-copy-right-wrapper .copy-right-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.footer-copy-right-wrapper a {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-decoration: none;
  color: #ffcf14;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.footer-copy-right-wrapper a:hover {
  color: #fff;
}

/* Complete care plans */

.breakthrough-page-banner-area {
  /* background: url("../public/images/fix-it-banner.png"); */
  min-height: 330px;
  max-height: 330px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
}
.breakthrough-page-banner-area .banner-head-txt {
  font-family: "Charis SIL", serif;
  font-style: normal;
  font-weight: 900;
  font-size: 62px;
  line-height: 62px;

  color: #ffffff;
}

.plans-section {
  padding-top: 100px;
}

.plans-section-head-div .plans-sect-head-text {
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 42px;
  color: #323232;
  font-family: "Charis SIL", serif;
}

/* .plans-section-head-div .plans-sect-head-text:after {
  content: "";
  position: absolute;
  width: 20%;
  height: 1px;
  top: 60px;
  left: 2%;
  border-bottom: 3px solid #ffcf14;
} */

.plans-section-head-div {
  margin-bottom: 50px;
}
.plans-sect-meta-div .breakthrough-para {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 42px;
  color: #323232;
  font-family: "Charis SIL", serif;
}

.plans-section-price-listing-row {
  margin-top: 160px;
  margin-bottom: 60px;
}

.single-price-listing-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 24.6476px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-top: 15px solid #3b3b3b;
}

.single-price-listing-div .single-price-feature-text {
  color: #323232;
  font-family: var(--robboto-font);
  font-weight: 700;
  font-size: 36.9714px;
  line-height: 43px;
}

.single-price-listing-div .single-price-amount-text {
  font-weight: 700;
  font-size: 61.619px;
  text-align: center;
  color: #323232;
  font-family: var(--robboto-font);
}
.single-price-listing-div .single-price-amount-div {
  margin: 40px 0;
}

.single-price-listing-div .single-price-meta-head {
  color: #323232;
  font-family: var(--robboto-font);
  font-style: normal;
  font-weight: 700;
  font-size: 19.7181px;
  line-height: 23px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.single-price-listing-div .single-price-listing-ul {
  margin-bottom: 50px;
}
.single-price-listing-div .single-price-listing-ul li {
  color: #323232;
  list-style: none;
  font-family: var(--robboto-font);
  font-weight: 400;
  font-size: 17.2533px;
  line-height: 35px;
}

.single-price-listing-div .single-price-listing-ul li::before {
  content: "•";
  display: inline-block;
  width: 20px;
  margin-left: 0;
  font-size: 30px;
  position: relative;
  top: 4px;
  color: #cbd5e0;
}

.single-price-feature-div,
.single-price-meta-div {
  padding: 36px 25px 0px 25px;
}
.single-price-meta-div {
  padding-top: 5px;
  /* min-height: 700px; */
}

.single-price-get-in-touch-text {
  margin-bottom: 0;
  text-decoration: none;
}
.single-price-get-in-touch-div a {
  font-family: var(--robboto-font);
  font-weight: 700;
  font-size: 19.7181px;
  line-height: 23px;
  color: #fff;
  padding: 20px 0;
  display: block;
  background: #323232;

  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.single-price-get-in-touch-div a:hover {
  background: #ffcf14;
}
.single-price-note-para {
  font-family: var(--robboto-font);
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;
}

.premium-pricing-list .single-price-listing-wrapper {
  border-top: 15px solid #ffcf14;
  position: relative;
}

.premium-pricing-list .single-price-listing-wrapper .single-price-meta-div {
  min-height: auto;
}

.premium-pricing-list
  .single-price-listing-wrapper
  .single-price-get-in-touch-div
  a {
  background: #ffcf14;
  color: #323232;
}

.recommended-sect {
  margin-bottom: 70px;
}

.recommended-sect .recommended-head-div {
  margin-bottom: 60px;
}
.border-lt {
  border-left: 1px solid #d6d6d6;
}
.border-rt {
  border-right: 1px solid #d6d6d6;
}
.border-btm {
  border-bottom: 1px solid #d6d6d6;
}
.border-tp {
  border-top: 1px solid #d6d6d6;
}
.recommended-plan-single-col {
  padding: 35px 10px 35px 35px;
}
.recommended-plan-second-col {
  padding-left: 60px;
}
.rec-head-text {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: #323232;
  font-family: var(--robboto-font);
}

.rec-single-meta-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  color: #323232;
  font-family: var(--robboto-font);
}

.rec-note-para {
  margin-top: 70px;
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
  font-family: var(--robboto-font);
}

.top-requested-sect .top-single-meta-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  color: #323232;
  font-family: var(--robboto-font);
}

.top-requested-sect .top-requested-single-col {
  padding: 35px 10px 35px 35px;
}

.top-requested-sect .top-requested-second-col {
  padding-left: 60px;
}
.top-requested-sect .top-req-number-span {
  font-weight: bold;
  font-size: 18px;
  margin-top: 6px;
}

.top-requested-sect {
  margin-bottom: 70px;
}

.call-to-act-sect {
  min-height: 450px;
  background: url("../public/images/call-to-act-bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  border-top: 15px solid #ffcf14;
  border-bottom: 15px solid #ffcf14;
  margin-top: 30px;
}

.call-to-act-col-wrapper {
  max-width: 814px;
  margin: 0 auto;
  background: #3c3b3bdb;
  border-radius: 5px;
  padding: 30px 50px 50px 50px;
}
.call-to-act-sect .call-to-act-para {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 46px;
  font-family: var(--robboto-font);
  color: #fff;
  text-align: left;
}

.call-to-act-btn-div {
  text-align: left;
}
.call-to-act-btn-div .call-to-act-btn-anch {
  display: block;
  background: #ffcf14;
  width: fit-content;
  padding: 13px 40px;
  border-radius: 5px;
  color: #323232;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  font-family: var(--robboto-font);
  margin-top: 40px;
}
.call-to-act-btn-div .call-to-act-btn-anch:hover {
  color: #fff;
  background: #000;
}

.page-id-142 .top-bar-main-div,
.page-id-164 .top-bar-main-div,
.page-id-70 .top-bar-main-div {
  display: none;
}

.page-id-59 .top-bar-main-div,
.page-id-57 .top-bar-main-div,
.page-id-55 .top-bar-main-div,
.page-id-3 .top-bar-main-div {
  display: none;
}
/* .breakthrough-page-banner-area{
border-top: 15px solid #FFCF14;
} */

.single-meta-ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  list-style: none;
  color: #323232;
  font-family: var(--robboto-font);
}

.single-meta-ul li::before {
  content: "✓";
  /* content: "•"; */
  display: inline-block;
  width: 20px;
  margin-left: 0;
  font-size: 22px;
  position: relative;
  top: 4px;
  color: #ffcf14;
  font-weight: 800;
}

/* ======== Rates page =========== */

.rates-term-sect {
  margin: 90px 0;
}
.rate-ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  list-style: none;
  color: #323232;
  font-family: var(--robboto-font);
}

.rate-ul li::before {
  /* content: '✓'; */
  content: "•";
  display: inline-block;
  width: 20px;
  margin-left: 0;
  font-size: 22px;
  position: relative;
  top: 0px;
  color: #323232;
  font-weight: 800;
}

.rates-content-wrapper {
  width: 100%;
  height: 100%;
  padding: 40px 20px;
  box-shadow: 0px 0px 24.6476px rgba(0, 0, 0, 0.1);
}

.rates-content-wrapper .plans-section-head-div .plans-sect-head-text:after {
  top: 100px;
  left: 7%;
}

.rates-img-div {
  display: flex;
  align-items: center;
}

/* ==== Fix it plan page  */
.breakthrough-page-banner-area .video-container {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 430px;
  border-bottom: 15px solid #ffcf14;
}

.breakthrough-page-banner-area .video-container video {
  max-width: max-content;
  height: 100%;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}
.breakthrough-page-banner-area {
  /* overflow-y: hidden; */
  position: relative;
  top: 120px;
  margin-bottom: 110px;
}
.ctaButton,
.ctaButton-white {
  display: block;
  background: #ffcf14;
  color: #fff;
  font-size: 25px;
  padding: 20px 10px;
  border-radius: 15px;
  text-decoration: none;
  text-align: center;
  border: solid 1px #ffcf14;
  font-family: sans-serif;
  width: inherit;
  margin: 0 auto;
}
.ctaButton:hover {
  background-color: transparent;
  color: white;
}
.ctaButton-white:hover {
  background-color: black;
  color: #ffcf14;
}
.sch-appointment {
  display: block;
  background: #ffcf14;
  color: #fff;
  font-size: 25px;
  padding: 20px 10px;
  text-decoration: none;
  border-radius: 15px;
  text-align: center;
  border: solid 1px #ffcf14;
  font-family: sans-serif;
  width: inherit;
  margin: 0 auto;
}
.sch-appointment:hover {
  background-color: black;
  color: #ffcf14;
}
.careplans-welcome-video-sect {
  min-height: 500px;
  max-height: 500px;
  position: relative;
}

.careplans-welcome-video-sect .video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  border-bottom: 15px solid #ffcf14;
}

.careplans-welcome-video-sect .video-container video {
  max-width: max-content;
  height: 100%;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}

/* ===== Services page ==== */

.services-page-main .top-requested-sect {
  padding-top: 100px;
}

.breakthrough-page-banner-area .banner-head-txt {
  text-shadow: 2px 2px #141313;
}

.view-more-btn-div {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;
}
.view-more-btn-div-three {
  text-align: center;
  margin-bottom: 40px;
}

.tier-view-more-btn-anch {
  padding: 7px 15px;
  cursor: pointer;
  font-size: 16px;
  line-height: 40px;
  /* color: #323232; */
  text-decoration: none;
  font-family: var(--robboto-font);
  background: #565555;
  color: #fff !important;
  border-radius: 3px;
  font-weight: 500;
}
.tier-view-more-btn-anch:hover {
  background-color: #ffcf14;
  color: black;
}

.single-price-listing-div .single-price-listing-ul {
  margin-bottom: 0;
}

.single-price-note-para {
  margin-top: 50px;
}

.premium-pricing-list .single-price-listing-wrapper .single-price-meta-div {
  min-height: auto;
}

.single-price-note-para {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.rates-img-wrapper img {
  height: 595px;
  width: 45vw;
  position: relative;
  top: -35px;
}
.border-yellow-bottom {
  border-bottom: 5px solid #ffcf14;
  padding-bottom: 10px;
}

/* legal Pages */

.pae-inner-content p {
  font-family: sans-serif;
  font-weight: 100;
  font-size: 18px;
  line-height: 32px;
}
.pae-inner-content h2 {
  font-size: 32px;
}
.trusted-img {
  height: 196px;
  width: 175px;
}
.trusted-img-2 {
  height: 196px;
  width: 250px;
  border-radius: 5%;
}

/* Careers Page */
.breakthrough-page {
  margin-top: 150px;
}
@media screen and (max-width: 768px) {
  .rates-img-wrapper img {
    height: 364px;
    width: 70vw;
    position: relative;
    top: -35px;
  }
  .single-price-listing-div .single-price-listing-ul li {
    line-height: 22px;
    font-size: 14px;
  }
  .plans-sect-meta-div .breakthrough-para {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 35px;
    color: #323232;
    font-family: "Charis SIL", serif;
  }
}

.footer-anch {
  text-decoration: none;
}
.widget_block {
  list-style: none;
}

.single-price-get-in-touch-text:hover {
  text-decoration: none;
  color: white;
}

.video-container-youtube {
  width: 80%;
  height: 500px;
  margin-bottom: 1rem;
}
.video-container-youtube-plans {
  width: 100%;
  height: 500px;
}

@media only screen and (max-width: 1200px) {
  .header .top-bar-info-main .top-bar-text-link {
    font-size: 20px;
  }
  .header .top-bar-info-main .top-bar-text-link .top-bar-phone {
    font-size: 30px;
  }
  .video-container-youtube {
    width: 95%;
    height: 360px;
  }
  .video-container-youtube-plans {
    width: 95%;
    height: 250px;
  }
}

@media only screen and (max-width: 992px) {
  .header .top-bar-info-main .top-bar-text-link {
    font-size: 16px;
  }
  .header .top-bar-info-main .top-bar-text-link .top-bar-phone {
    font-size: 22px;
  }
  .footer-second-row .footer-small-head {
    font-size: 19px;
  }
  .footer-second-row .footer-social-links-div .footer-social-links-wrapper {
    margin: unset;
    width: unset;
  }
  .our-commitment-sect .single-commitment-head-text {
    font-size: 22px;
  }
  .breakthrough-page h2:after {
    top: 90px;
  }
  .premium-pricing-list .single-price-listing-wrapper {
    position: unset;
    top: 0;
  }
  .single-price-listing-div {
    margin-bottom: 40px;
  }

  .plans-section-head-div .plans-sect-head-text {
    font-size: 24px;
  }
  .breakthrough-page-banner-area .banner-head-txt {
    font-size: 48px;
  }
  .single-price-listing-div .single-price-amount-text {
    font-size: 48px;
  }
}

@media only screen and (max-width: 768px) {
  .header .top-bar-info-main .top-bar-text-link {
    font-size: 12px;
  }
  .header .top-bar-info-main .top-bar-text-link .top-bar-phone {
    font-size: 18px;
  }
  .header .top-bar-info-main img {
    width: 40px;
    height: 40px;
    padding-top: 5px;
  }
  .header .top-bar-info-main {
    padding: 10px 0;
  }

  .hero-banner-sect .banner-head-text {
    font-size: 44px;
    line-height: 66px;
  }
  .hero-banner-sect .banner-desc-text {
    font-size: 26px;
    line-height: 40px;
  }
  .great-value-sect .container {
    width: 100%;
    max-width: 100%;
  }
  .great-value-sect .great-value-sect-col-wrapper {
    max-width: 100%;
  }
  .great-value-sect-col {
    padding: 0;
  }
  .contact-form-sect .contact-head-text {
    font-size: 34px;
  }
  .contact-form-sect .contact-desc-para {
    font-size: 18px;
    line-height: 30px;
  }
  .contact-form-sect .contact-form-black-div-wrapper {
    max-width: 100%;
    padding: 0 10px;
  }
  .contact-form-sect .contact-form-main-div .form-bottom-para {
    text-align: center;
  }
  .contact-form-sect .contact-form-main-div .form-bottom-para br {
    display: none;
  }
  .contact-form-sect .contact-head-text:after {
    left: 38%;
  }
  .who-we-are-sect .who-we-are-head-text {
    font-size: 36px;
  }
  .who-we-are-sect .who-we-are-head-div {
    margin-bottom: 50px;
  }
  .who-we-are-sect .who-we-are-head-text:after {
    top: 55px;
    left: 4%;
  }
  .who-we-are-sect .who-we-are-para {
    font-size: 18px;
    line-height: 30px;
  }

  .trusted-img-div {
    margin-bottom: 30px;
  }

  .footer-logo-wrapper {
    text-align: center;
    margin-bottom: 40px;
  }

  .footer-addr-wrapper {
    text-align: center;
    margin-bottom: 30px;
  }
  .footer-second-row .footer-addr-wrapper .footer-small-head:after {
    left: 41%;
  }
  .footer-second-row .footer-social-links-wrapper .footer-small-head:after {
    left: 42%;
  }
  .footer-second-row .footer-social-links-div .footer-social-links-wrapper {
    width: auto;
    margin: unset;
    text-align: center;
  }
  .footer-copy-right-wrapper .copy-right-text {
    line-height: 30px;
  }
  .who-we-are-sect {
    padding: 40px 0 10px 0;
  }
  .contact-form-sect .sch-appointment-anch {
    width: 100%;
  }
  .border-rt {
    border-right: 0;
  }
  .top-requested-sect .top-requested-single-col,
  .recommended-plan-single-col {
    padding-left: 20px;
    padding-right: 10px;
  }
  .call-to-act-sect .call-to-act-para {
    font-size: 25px;
  }

  .rates-img-wrapper img {
    height: auto;
    position: unset;
    top: 0;
  }
}

@media only screen and (max-width: 640px) {
  .loading-gif {
    width: 90vw;
  }
  .nav-logo {
    width: 20vw;
  }
  .top-bar-phone {
    font-size: 20px;
  }
  .top-bar-img {
    width: 10vw;
  }
  .plans-sect-meta-div .breakthrough-para {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #323232;
    font-family: "Charis SIL", serif;
  }
  .single-price-feature-div,
  .single-price-meta-div {
    padding: 36px 0px 0px 0px;
  }
  .single-meta-ul li {
    font-size: 14px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 540px) {
  .top-bar-wrapper {
    margin-top: 25%;
  }
  .header .top-bar-info-main img {
    height: 30px;
    width: 30px;
    margin-right: -8px;
    padding-top: 10px;
    top: -6px;
    left: unset;
  }
  .header .top-bar-info-main .top-bar-text-link {
    font-size: 16px;
    line-height: 25px;
  }
  .header .top-bar-info-main .top-bar-text-link .top-bar-phone {
    font-size: 12px;
  }

  .hero-banner-sect .banner-head-text {
    font-size: 25px;
    line-height: 40px;
  }
  .hero-banner-sect .banner-desc-text {
    font-size: 18px;
    line-height: 28px;
  }
  .hero-banner-sect .banner-anch-text {
    font-size: 18px;
  }
  .great-value-sect .great-value-first-head-text {
    font-size: 20px;
  }
  .great-value-sect .great-value-bordered-head {
    font-size: 26px;
  }
  .contact-form-sect .contact-head-text {
    font-size: 26px;
  }
  .contact-form-sect .contact-head-text:after {
    left: 34%;
  }
  .contact-form-sect .contact-desc-para {
    font-size: 14px;
    line-height: 25px;
  }
  .footer-copy-right-wrapper .copy-right-text {
    font-size: 16px;
  }
  .our-commitment-sect .single-commitment-head-text {
    font-size: 20px;
  }
  .who-we-are-sect .who-we-are-head-text {
    font-size: 26px;
  }
  .who-we-are-sect .who-we-are-para {
    font-size: 14px;
  }
  .header .top-bar-info-main {
    text-align: center;
  }
  .hero-banner-sect .banner-scroll-btn-row .banner-scroll-txt {
    font-size: 20px;
  }
  .hero-banner-sect .video-container video {
    max-width: 100%;
  }
  .header .top-bar-info-main .top-bar-text-link .top-bar-phone {
    font-size: 17px;
  }
  .breakthrough-page {
    padding: 50px 0px;
  }
  .contact-form-sect .sch-appointment-anch {
    font-size: 18px;
    padding: 10px 10px;
  }
  .plans-section-head-div .plans-sect-head-text {
    font-size: 20px;
  }
  .call-to-act-sect .call-to-act-para {
    font-size: 20px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .cstm-nav-setting {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .top-bar-wrapper {
    margin-top: 25%;
  }
  .breakthrough-page h2 {
    font-size: 26px;
  }
  .breakthrough-page h3 {
    font-size: 22px;
  }
  .breakthrough-page-banner-area .banner-head-txt {
    font-size: 30px;
  }
  .recommended-head-div .plans-sect-head-text {
    line-height: 30px;
  }
  .recommended-head-div .plans-sect-head-text:after {
    top: 70px;
    left: 5%;
  }

  .plans-section-head-div .plans-sect-head-text:after,
  .top-requested-head-div .plans-sect-head-text:after {
    left: 7%;
  }
  .call-to-act-col-wrapper {
    padding: 30px 20px 50px 20px;
  }
}

@media only screen and (max-width: 380px) {
  .footer-second-row .footer-small-head {
    font-size: 18px;
  }
  .header .top-bar-info-main .top-bar-text-link {
    font-size: 9px;
  }
  .cstm-nav-setting {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .top-bar-wrapper {
    margin-top: 25%;
  }
}

.book-appointment .book-appointment-heading {
  font-family: "Charis SIL", serif;
  font-size: 1.8rem;
}
.btn-mustard {
  background-color: #ffcf14 !important;
  border: 2px solid #ffcf14 !important;
}
.btn-mustard:hover {
  border: 2px solid black !important;
  background-color: black !important;
  color: #ffcf14 !important;
}
.bg-light-gray {
  background-color: #d6d6d6;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.container-video {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 30%; /* 1:1 Aspect Ratio */
  margin-left: 0 auto;
}
div .mid-heading {
  text-align: center !important;
  text-align: center;
}

.gGPcib {
  align-items: center;
  width: 95%;
  margin: 0 auto;
}
.gGPcib h1 {
  font-family: "Charis SIL", serif;
  border-bottom: 5px solid #ffcf14;
  padding-bottom: 10px;
}

.gGPcib h2 {
  font-family: "Charis SIL", serif;
  margin: 10px auto;
  width: 70%;
  text-align: center;
}
.rwlGBPProfile::before {
  content: "What we do : ";

  font-style: italic;
  border-bottom: 2px solid #ffcf14;
  padding-bottom: 2px;
}
.rwlGBPProfile {
  line-height: 2rem;
}
.rwlGBPCategories {
  font-style: italic;
}
.rwlTopCitiesContainer,
.rwlJobLocations h3 {
  text-align: center;
  font-family: "Charis SIL", serif;
  padding-bottom: 10px;
  margin: 20px auto;
}
