/* ServicePackage.css */

.service-package {
    background-color: #ffeeee;
    border-radius: 8px;
    overflow: hidden;
    padding: 2rem 1rem;
  }
  
  .service-package-title {
    font-family: Charis SIL;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
  }
  
  .service-package-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .service-package-image-container {
    flex: 1;
    max-width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .service-package-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .service-package-item {
    font-family: Charis SIL;
    font-size: 2.2rem;
    font-weight: 400;
    text-align: right;
  }
  
  .service-package-price {
    font-size: 1.2rem;
    text-align: right;
    color: #333;
    margin: 1rem 0;
  }
  
  .service-package-original-price {
    font-size: 1.6rem;
    margin-right: 0.5rem;
    text-decoration: line-through;
  }
  
  .service-package-discounted-price {
    font-size: 2.2rem;
    font-family: Charis SIL;
    font-weight: 400;
    color: #333;
  }
  
  .service-package-note {
    font-family: Charis SIL;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    text-align: right;
  }
  
  .service-package-button {
    margin-top: 2rem;
    text-align: right;
  }
  
  /* Responsive adjustments */
  
  /* For tablets */
  @media (max-width: 768px) {
    .service-package {
      padding: 1.5rem;
    }
  
    .service-package-title {
      font-size: 2rem;
    }
  
    .service-package-item {
      font-size: 1.2rem;
      text-align: left;
    }
  
    .service-package-price {
      font-size: 1rem;
      text-align: left;
    }
  
    .service-package-original-price {
      font-size: 1.4rem;
    }
  
    .service-package-discounted-price {
      font-size: 1.8rem;
    }
  
    .service-package-note {
      font-size: 0.9rem;
      text-align: left;
    }
  
    .service-package-button {
      text-align: center;
    }
  }
  
  /* For mobile devices */
  @media (max-width: 576px) {
    .service-package {
      padding: 1rem;
    }
  
    .service-package-title {
      font-size: 2rem;
    }
  
    .service-package-item {
      font-size: 1.4rem;
      text-align: left;
    }
  
    .service-package-price {
      font-size: 1.3rem;
      text-align: left;
    }
  
    .service-package-original-price {
      font-size: 1.4rem;
    }
  
    .service-package-discounted-price {
      font-size: 1.7rem;
    }
  
    .service-package-note {
      font-size: 1.1rem;
      text-align: left;
    }
  
    .service-package-button {
      text-align: center;
    }
  
    .service-package-image-container {
      order: -1; /* Move the image above the text on mobile */
      padding: 0.5rem;
    }
  }
  