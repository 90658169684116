.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.popup-content {
  background-color: white;
  padding: 3rem;
  border-radius: 12px;
  width: 95%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.popup-content form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.close-button {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: none;
  border: none;
  font-size: 1.75rem;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s;
  z-index: 1;
}

.close-button:hover {
  color: #333;
}

.popup-content h2 {
  text-align: center;
  color: #2c3e50;
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.form-header {
  text-align: center;
  margin-bottom: 2.5rem;
  color: #34495e;
  line-height: 1.6;
  font-size: 1.1rem;
}

.form-header a {
  color: #007bff;
  font-weight: 600;
  text-decoration: none;
}

.form-header a:hover {
  text-decoration: underline;
}

/* Service Type Styling */
.service-type {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.2rem;
  color: #2c3e50;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.radio-label:hover {
  background-color: #e9ecef;
}

.radio-label input[type="radio"] {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0;
}

/* Form Layout */
.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 1.5rem;
  flex: 1;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #2c3e50;
  font-size: 1rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.875rem;
  border: 1.5px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.2s;
  color: #2c3e50;
  background-color: #fff;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

.form-group textarea {
  min-height: 120px;
  resize: vertical;
}

/* Checkbox Styling */
.checkbox-group {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: normal;
  cursor: pointer;
}

.checkbox-group input[type="checkbox"] {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0;
}

.consent-text {
  font-size: 0.95rem;
  color: #666;
  margin-top: 1rem;
  line-height: 1.6;
  padding: 1rem;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #e9ecef;
}

/* Submit Button */
.submit-button {
  background-color: #007bff;
  color: white;
  padding: 1.25rem 2rem;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  margin-top: 2rem;
  transition: all 0.2s;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.submit-button:hover {
  background-color: #0056b3;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.submit-button:active {
  transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 768px) {
  .popup-content {
    padding: 2rem;
    margin: 1rem;
    width: 100%;
  }

  .form-row {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .service-type {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
  }

  .radio-label {
    width: 100%;
    justify-content: center;
  }

  .popup-content h2 {
    font-size: 1.8rem;
  }

  .form-header {
    font-size: 1rem;
  }

  .submit-button {
    padding: 1rem;
    font-size: 1.1rem;
  }
}

/* Custom Scrollbar */
.popup-content::-webkit-scrollbar {
  width: 8px;
}

.popup-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.popup-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.popup-content::-webkit-scrollbar-thumb:hover {
  background: #666;
}
