.customization-section {
  width: 100vw;
  height: 600px;
  background-image: url('../../../../assets/images/christmas/customization_section.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.customization-button {
  z-index: 1;
}

@media (max-width: 576px) {
  .customization-section {
    height: 350px;
  }
}