.packages-heading {
  font-family: Inter;
  font-size: 32px;
  font-weight: 500;
  line-height: 38.73px;
  letter-spacing: 0.12em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-align: center;
  color: #323232;
  margin-bottom: 4rem;
}
